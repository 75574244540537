$normal-vars: (
	'ico_arrow_left': (
		offset-x: -232px,
		offset-y: -140px,
		width: 36px,
		height: 29px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_arrow_right': (
		offset-x: -100px,
		offset-y: -243px,
		width: 36px,
		height: 29px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_back': (
		offset-x: -232px,
		offset-y: -106px,
		width: 43px,
		height: 30px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_back_ta': (
		offset-x: 0px,
		offset-y: -278px,
		width: 53px,
		height: 37px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_bars': (
		offset-x: -431px,
		offset-y: -183px,
		width: 46px,
		height: 23px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_brand': (
		offset-x: -165px,
		offset-y: -179px,
		width: 109px,
		height: 29px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_brand_mo': (
		offset-x: -165px,
		offset-y: -212px,
		width: 82px,
		height: 22px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_close_sm': (
		offset-x: -232px,
		offset-y: -63px,
		width: 39px,
		height: 39px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_earth': (
		offset-x: -459px,
		offset-y: -246px,
		width: 24px,
		height: 23px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_earth_hover': (
		offset-x: -84px,
		offset-y: -278px,
		width: 24px,
		height: 23px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_facebook': (
		offset-x: -22px,
		offset-y: -319px,
		width: 14px,
		height: 24px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_facebook_hover': (
		offset-x: -40px,
		offset-y: -319px,
		width: 14px,
		height: 24px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_pagination': (
		offset-x: -451px,
		offset-y: -215px,
		width: 25px,
		height: 25px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_pagination_on': (
		offset-x: -421px,
		offset-y: -215px,
		width: 26px,
		height: 25px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_pagination_small': (
		offset-x: -464px,
		offset-y: -129px,
		width: 12px,
		height: 12px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_pagination_small_on': (
		offset-x: -473px,
		offset-y: -77px,
		width: 11px,
		height: 11px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_pagination_ta': (
		offset-x: -464px,
		offset-y: -152px,
		width: 19px,
		height: 18px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_pagination_ta_on': (
		offset-x: -112px,
		offset-y: -278px,
		width: 19px,
		height: 18px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_play': (
		offset-x: 0px,
		offset-y: -179px,
		width: 96px,
		height: 95px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_play_ta': (
		offset-x: -100px,
		offset-y: -179px,
		width: 61px,
		height: 60px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_read_story': (
		offset-x: -288px,
		offset-y: -45px,
		width: 186px,
		height: 28px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_read_story_kr': (
		offset-x: -288px,
		offset-y: -183px,
		width: 139px,
		height: 28px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_read_story_ta': (
		offset-x: -288px,
		offset-y: -152px,
		width: 172px,
		height: 27px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_read_story_ta_kr': (
		offset-x: -288px,
		offset-y: -215px,
		width: 129px,
		height: 27px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_rec': (
		offset-x: -436px,
		offset-y: -77px,
		width: 33px,
		height: 48px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_rec_ta': (
		offset-x: -57px,
		offset-y: -278px,
		width: 23px,
		height: 32px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_rotate': (
		offset-x: 0px,
		offset-y: -63px,
		width: 112px,
		height: 112px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_rotate_on': (
		offset-x: -116px,
		offset-y: -63px,
		width: 112px,
		height: 112px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_rotate_ta': (
		offset-x: -362px,
		offset-y: -77px,
		width: 70px,
		height: 70px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_rotate_ta_off': (
		offset-x: -288px,
		offset-y: -77px,
		width: 70px,
		height: 71px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_scroll': (
		offset-x: 0px,
		offset-y: -319px,
		width: 18px,
		height: 37px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_skip': (
		offset-x: -288px,
		offset-y: -246px,
		width: 82px,
		height: 28px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_skip_ta': (
		offset-x: -374px,
		offset-y: -246px,
		width: 81px,
		height: 26px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_twitter': (
		offset-x: -251px,
		offset-y: -212px,
		width: 27px,
		height: 21px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_twitter_hover': (
		offset-x: -140px,
		offset-y: -243px,
		width: 27px,
		height: 21px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_youtube': (
		offset-x: -436px,
		offset-y: -129px,
		width: 24px,
		height: 16px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'ico_youtube_hover': (
		offset-x: -171px,
		offset-y: -243px,
		width: 24px,
		height: 16px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'logo': (
		offset-x: -288px,
		offset-y: 0px,
		width: 196px,
		height: 41px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
	'logo_ta': (
		offset-x: 0px,
		offset-y: 0px,
		width: 284px,
		height: 59px,
		total-width: 484px,
		total-height: 356px,
		imageSrc: '../img/normal.png'
	),
);
$normal-origin: (
  total-width: 484px,
  total-height: 356px,
  imageSrc: '../img/normal.png'
);

@mixin sprite-size($image) {
	background-size: map-get($image, 'total-width') map-get($image, 'total-height');
}

@mixin sprite-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-normal {
  @include sprite-size($normal-origin);
  @include sprite-image($normal-origin);
}

@mixin sp-normal($image, $size: true) {
  @include set-normal;
	background-position: map-get(map-get($normal-vars, $image), 'offset-x') map-get(map-get($normal-vars, $image), 'offset-y');
	@if $size {
		width: map-get(map-get($normal-vars, $image), 'width');
		height: map-get(map-get($normal-vars, $image), 'height');
	}
}
