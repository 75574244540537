$screen-xsm: 320px;
$screen-sm: 720px;
$screen-md: 1024px;
$screen-lg: 1366px;
$screen-xlg: 1920px;

$black: #000;
$white: #fff;

$z-indexes: (
  default: 1,
  dropdown: 2,
  hoverLayer: 3,
  nav: 4,
  alert: 5,
  full: 6
);
