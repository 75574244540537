// Section Key visual
.section_key_visual {
  .background {
    &::after {
      @include absolute-full;
      background-position: center;
      background-size: cover;
      content: "";
    }
  }

  .box_content {
    right: 14%;
    top: 36%;
    text-align: center;

    .title {
      width: 38.333vw;
      max-width: 878px;
      margin: 0 auto;
      opacity: 0;

      > .img {
        width: 100%;
      }

      &_sub {
        position: relative;
        width: 41.14vw;
        max-width: 790px;
        padding-bottom: 8.98%;
        margin: 1.979vw auto 0 auto;
        background-image: url(../img/pc/kv/pc_kv_003.png);
        background-size: 100% 100%;
        opacity: 0;

        .img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 24.6vw;
          max-width: 473px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .desc {
    position: absolute;
    right: 48px;
    bottom: 46px;
    width: 21.3vw;
    min-width: 205px;
    font-size: 0;

    .img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: $screen-md) {
  .section_key_visual {
    .box_content {
      right: 5.4%;
      top: 33.3%;

      .title {
        width: 48.242vw;

        &_sub {
          width: 65.039vw;
          margin-top: 2.539vw;
          padding-bottom: 8.708%;

          .img {
            width: 36.6vw;
          }
        }
      }
    }

    .desc {
      right: 50%;
      bottom: 49px;
      width: 36.3vw;
      transform: translateX(50%);
    }
  }
}

@media (max-width: $screen-sm) {
  .section_key_visual {
    .box_content {
      top: initial;
      right: 50%;
      bottom: 141px;
      transform: translateX(50%);
      .title {
        width: 270px;

        &_sub {
          width: 347px;
          margin-top: 12px;
          padding-bottom: 9.1%;

          .img {
            width: 198px;
          }
        }
      }
    }

    .desc {
      bottom: 23px;
    }
  }
}

@media (orientation: landscape) {
  .section_key_visual {
    .background {
      &::after {
        background-image: url(../img/pc/kv/pc_kv_001.png);
      }
    }
  }
}

@media (orientation: portrait) {
  .section_key_visual {
    .background {
      &::after {
        background-image: url(../img/mo/kv/mo_kv_001.png);
      }
    }
  }
}

@media (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .section_key_visual {
    .box_content {
      .title {
        height: 60px;
        width: auto;

        .img{
          width: auto;
          height: 100%;
        }
      }

      .title_sub {
        width: 280px;
        margin-top: 12px;

        .img {
          width: 200px;
        }
      }
    }

    .desc {
      bottom: 30px;
    }
  }
}
