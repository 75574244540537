.combat {
  &_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, calc(-50% + 8.425vh));

    @include respond-below($screen-md) {
      transform: translate(-50%, calc(-50% + 7.519vw));
    }

    @include respond-below($screen-sm) {
      transform: translate(-50%, calc(-50% + 11.805vw));
    }
  }

  &_heading {
    position: absolute;
    top: -19.629vh;
    left: 50%;
    z-index: 2;
    width: 200%;
    transform: translateX(-50%);
    text-align: center;
    @include respond-below($screen-md) {
      top: -17.578vw;
    }

    @include respond-below($screen-sm) {
      top: -25.972vw;
    }
  }

  &_title {
    opacity: 0;

    img {
      margin: 0 auto;
      @include vw-convert-pc(width, 407);
      @include vw-convert-pc(height, 138);

      @include respond-below($screen-md) {
        width: 32.128vw;
        height: 12.304vw;
      }
    }

    @include respond-below($screen-md) {
      margin-left: -14px;
    }
    .category {
      font-size: 10.833vw;
      line-height: 0.72;
      letter-spacing: -0.78px;
      font-weight: 400;
      text-transform: uppercase;
      color: #bfa673;
    }

    @include respond-below($screen-md) {
      top: -17.578vw;
    }
  }

  &_sub_title {
    @include vw-convert-pc(margin-top, -25);
    @include vw-font-size-pc(20);
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
    text-shadow: 0px 1px 2px #000;
    color: #938d83;
    text-transform: uppercase;
    opacity: 0;

    @include respond-below($screen-md) {
      margin-top: -2.832vw;
      font-size: 1.5625vw;
      letter-spacing: -0.02em;
    }

    @include respond-below($screen-sm) {
      font-size: 2.777vw;
      margin-top: 1.666vw;
      letter-spacing: normal;
    }
  }

  &_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &_prev,
  &_next {
    top: initial;
    width: 44px;
    font-size: 0;
    bottom: -14.111vw;
    @include respond-above($screen-sm + 1) {
      display: none;
    }
  }

  &_prev {
    position: absolute;
    left: calc(50% - 163px);
  }
  &_next {
    position: absolute;
    left: calc(50% + 120px);
  }

  &_pagination {
    &.swiper-pagination {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      bottom: -14.111vw;
      @include respond-above($screen-sm + 1) {
        display: none;
      }
    }
    .swiper-pagination {
      &-bullet {
        position: relative;
        width: 36px;
        height: 40px;
        padding: 11px;
        margin: 0 !important;
        background: none;
        font-size: 0;
        opacity: 1;

        &:first-child {
          &::before {
            opacity: 0;
          }
        }

        &:last-child {
          &::after {
            opacity: 0;
          }
        }

        &::before,&::after {
          position: absolute;
          left: 0;
          top: 50%;
          width: 12px;
          height: 1px;
          margin: 0;
          background-color: rgba(191, 162, 138, 0.3);
          transform: translateY(-50%);
          content: "";
        }

        &::after {
          left: initial;
          right: 0;
        }
      }

      &-bullet-active {
        .ico_pagination {
          @include sp-normal("ico_pagination_small_on");
        }
      }
    }
  }

  &_img_box {
    @include absolute-full();
    @include respond-above($screen-sm + 1) {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
      &.is_scale {
        transform: scale(1);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_cards {
    padding-bottom: 30px;
    @include vw-convert-pc(width, 1038);
    @include respond-below($screen-md) {
      width: 62.695vw;
    }

    @include respond-below($screen-sm) {
      width: 78.888vw;
      padding-bottom: 4.1666vw;
    }
  }

  &_card {
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1;
    @include respond-below($screen-md) {
      &:not(.swiper-slide-thumb-active) {
        .ico_rotate {
          @include sp-normal("ico_rotate_ta_off");

          &::after {
            background-image: url(../img/common/ico_plus_ta_off.png);
          }
        }
      }
    }

    @include respond-above($screen-md + 1) {
      &:hover,&.swiper-slide-thumb-active {
        .btn_view {
          .ico_rotate {
            @include sp-normal("ico_rotate_on");

            &::after {
              transform: translate(-50%, -50%) rotate(90deg);
              background-image: url(../img/common/ico_plus_on.png);
            }
          }
        }
      }
    }

    @media (max-width: 1023px) and (max-height: 767px) and (orientation: landscape) {
      &:not(.swiper-slide-thumb-active) {
        .ico_rotate {
          width: 14vh;
          height: 14vh;
          background-image: url(../img/common/ico_rotate_off.png);
          @include background-full
        }
      }
    }

    .thumb {
      position: relative;
      overflow: hidden;
      border: 1px solid rgba(191,162,138,0);
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .5s;
      }

      .is_on {
        @include absolute-full;
      }
    }

    &.swiper-slide {
      @include respond-above($screen-sm + 1) {
        .thumb .is_on {
          opacity: 0;
        }

        .combat_text .is_on {
          opacity: 0;
        }
      }

      &-thumb-active {
        .thumb {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/pc/combat/pc_combat_013.png);
            background-size: 100% 100%;
            content: "";

            @include respond-below($screen-sm) {
              content: initial;
            }
          }
        }

        @include respond-above($screen-sm + 1) {
          .thumb .is_off {
            opacity: 0;
          }
          .thumb .is_on {
            opacity: 1;
            transform: scale(1.05);
          }
          .combat_text .is_off {
            opacity: 0;
          }
          .combat_text .is_on {
            opacity: 1;
          }
        }
      }
    }

    .btn_view {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 0;
      transform: translate(-50%, -50%);
    }
  }

  &_caption {
    position: absolute;
    @include vw-convert-pc(bottom, 37);
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    transition: .5s;

    @include respond-below($screen-md) {
      bottom: 1.66vw;
    }

    img {
      width: 100%;
    }
  }

  &_popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    transform: scale(0);
    &.is_show {
      animation: unfoldIn 0.75s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
    &.is_hidden {
      animation: unfoldOut 1s cubic-bezier(0.165, 0.84, 0.44, 1) backwards;
    }

    &_wrap {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;

      &.is_show {
        opacity: 1;
        visibility: visible;
      }
      &:nth-child(2) {
        .combat_popup_video {
          &::before {
            background: url(../img/pc/combat/pc_combat_005.png) center/100% no-repeat;

            @include respond-below($screen-md) {
              background: url(../img/ta/combat/ta_combat_005.png) center/100% no-repeat;
            }

            @include respond-below($screen-sm) {
              background: url(../img/mo/combat/mo_combat_005.png) center/100% no-repeat;
            }
          }
        }
      }
      &:nth-child(3) {
        .combat_popup_video {
          &::before {
            background: url(../img/pc/combat/pc_combat_006.png) center/100% no-repeat;

            @include respond-below($screen-md) {
              background: url(../img/ta/combat/ta_combat_006.png) center/100% no-repeat;
            }

            @include respond-below($screen-sm) {
              background: url(../img/mo/combat/mo_combat_006.png) center/100% no-repeat;
            }
          }
        }
      }
    }

    &_bg {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include respond-above($screen-md + 1) {
        display: none;
      }
    }

    &_title {
      position: absolute;
      top: 14.35185vh;
      left: 50%;
      width: 100%;
      @include vw-font-size-pc(63);
      font-weight: 400;
      line-height: 0.9;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 0px 1px 2px #000;
      color: #bfa673;
      transform: translateX(-50%);

      span {
        display: inline-block;
        opacity: 0;
      }

      @include respond-below($screen-md) {
        top: 16.2760416667vh;
        font-size: 5.175vw;
        line-height: 0.88;
      }

      @include respond-below($screen-sm) {
        top: initial;
        bottom: 42.421vh;
        font-size: 6.9444vw;
        line-height: 1.04;
      }
    }

    &_content {
      position: absolute;
      bottom: 16.944vh;
      left: 50%;
      width: 100%;
      height: 19.074vh;
      font-family: beaufort-pro;
      @include vw-font-size-pc(18);
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: 0.36px;
      text-align: center;
      text-shadow: 0px 1px 2px #000;
      color: #a19c92;
      transform: translateX(-50%);

      @include respond-below($screen-md) {
        bottom: 14.973vh;
        height: 24.609vh;
        font-size: 1.562vw;
        line-height: 1.38;
        letter-spacing: 0.32px;
      }

      @include respond-below($screen-sm) {
        bottom: 8.203vh;
        min-height: 31.875vh;
        font-size: 3.333vw;
        line-height: 1.25;
        letter-spacing: 0.066vw;
      }
    }

    &_desc {
      opacity: 0;

      &:not(:first-child) {
        @include vw-convert-pc(margin-top, 24);

        @include respond-below($screen-md) {
          margin-top: 2.148vw;
        }

        @include respond-below($screen-sm) {
          margin-top: 2.265vh;
        }
      }
    }

    &_media {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
      &.combat_video {
        width: 100%;
        height: 100%;

        @include respond-above($screen-md + 1) {
          width: 177.777vh;
          height: 56.25vw;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            background: url(../img/pc/combat/pc_combat_004.png) center/100% no-repeat;
            width: 100%;
            height: 100%;
            content: "";

            @include respond-below($screen-md) {
              background: url(../img/ta/combat/ta_combat_004.png) center/100% no-repeat;
            }

            @include respond-below($screen-sm) {
              background: url(../img/mo/combat/mo_combat_004.png) center/100% no-repeat;
            }
          }
        }
      }

      video {
        width: 100%;
        height: 100%;

        @include respond-below($screen-md) {
          display: none;
        }
      }
    }

    .btn_close {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 1;
      padding: 10px;
      font-size: 0;
      @include respond-below($screen-md) {
        top: 38px;
        right: 42px;
      }

      @include respond-below($screen-sm) {
        top: 15px;
        right: 14px;
      }
    }
  }
}

@media (max-width: 1023px) and (max-height: 767px) and (orientation: landscape) {
  .combat {
    &_cards {
      width: 100vh;
    }

    &_card {
      &:hover {
        .btn_view .ico_rotate {
          width: 14vh;
          height: 14vh;
          background-image: url(../img/common/ico_rotate_mo.png);
          background-position: 0;
          background-size: 100%;

          &::after {
            width: 6vh;
            height: 6vh;
          }
        }
      }
    }

    &_heading {
      top: -100px;
    }

    &_title {
      img {
        width: auto;
        height: 70px;
      }
    }

    &_sub_title {
      margin-top: -15px;
    }

    &_popup {
      .btn_close {
        top: 17px;
        right: 16px;
      }

      &_title {
        top: 30px;
        bottom: initial;
        font-size: 30px;
      }

      &_content {
        bottom: 20px;
        height: auto;
        font-size: 15px;
      }

      &_desc {
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 720px) and (max-height: 767px) and (orientation: landscape) {
  .combat {
    &_cards {
      width: 50vh;
    }
  }
}
