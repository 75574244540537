.header {
  display: flex;
  visibility: hidden;
  align-items: center;
  position: absolute;
  top: -2px;
  left: 0;
  z-index: map-get($z-indexes, nav);
  width: 100%;
  padding: 30px 42px 0 48px;
  background-image: url(../img/common/header_bg.png);
  background-size: auto 100%;
  opacity: 0;
  box-sizing: border-box;
  transition: 0.5s;

  &.is_show {
    visibility: visible;
    opacity: 1;
  }

  &.is_delay {
    transition-delay: 2.2s;
  }

  &_logo {
    transform: translateY(4px);
  }

  .logo {
    display: inline-block;
    font-size: 0;
  }

  .btn_close {
    display: none;
    position: absolute;
    top: 41px;
    left: calc(100% + 11px);
    padding: 8px;
    font-size: 0;
  }
}

.gnb {
  $block: &;

  &_lst {
    display: flex;
    align-items: center;
    margin-left: 48px;
  }

  &_item {
    position: relative;

    + #{$block}_item {
      margin-left: 42px;
    }

    &:hover {
      #{$block}_link {
        color: #ceb98e;
      }
    }
  }

  &_link {
    display: block;
    padding: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.1;
    text-transform: uppercase;
    color: #909090;
    transition: 0.2s;

    @media (max-width: $screen-md) {
      color: rgba(133, 133, 133, 0.7);
    }

    &:hover,
    &.is_active {
      color: #ceb98e;
      opacity: 1;
    }
  }
}

.sns {
  $block: &;
  margin-left: auto;

  &_lst {
    display: flex;
    align-items: center;
    font-size: 0;
  }

  &_item {
    + #{$block}_item {
      margin-left: 11px;
    }
  }

  &_link {
    display: inline-block;
    padding: 6px;
  }

  &_youtube {
    &:hover {
      .ico_youtube {
        @include sp-normal("ico_youtube_hover");
      }
    }
  }

  &_twitter {
    &:hover {
      .ico_twitter {
        @include sp-normal("ico_twitter_hover");
      }
    }
  }

  &_facebook {
    &:hover {
      .ico_facebook {
        @include sp-normal("ico_facebook_hover");
      }
    }
  }
}

.lnb {
  $block: &;
  position: relative;
  margin-left: 11px;

  &_select {
    padding: 6px;
    font-size: 0;

    &:hover {
      .ico_earth {
        @include sp-normal("ico_earth_hover");
      }
    }
  }

  &_lst {
    visibility: hidden;
    position: absolute;
    top: calc(100% + 15px);
    right: 6px;
    width: 148px;
    height: 156px;
    padding: 13px 0;
    border: 1px solid rgba(100, 100, 100, 0.5);
    background-color: rgba(19, 19, 19, 0.5);
    opacity: 0;
    box-sizing: border-box;
    transition: opacity 0.5s;

    @include respond-above($screen-md + 1) {
      width: 128px;
      height: auto;
      padding: 12px 0;
    }
    &.is_show {
      visibility: visible;
      opacity: 1;
    }
  }

  &_item {
    position: relative;

    & + #{$block}_item {
      margin-top: 2px;
    }
  }

  &_link {
    display: block;
    padding: 6px 14px;
    font-family: beaufort-pro;
    font-size: 16px;
    line-height: 1.25;
    color: rgba(255, 255, 255, 0.35);
    letter-spacing: 0.48px;
    transition: color 0.2s;

    @include respond-above($screen-md + 1) {
      padding: 7px 14px;
    }
    &.is_selected,
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.menu_bars {
  visibility: hidden;
  position: fixed;
  top: 33px;
  left: 52px;
  z-index: map-get($z-indexes, dropdown);
  padding: 15px 0;
  font-size: 0;
  opacity: 0;
}

@media (max-width: $screen-lg) {
  .gnb {
    &_item {
      & + .gnb_item {
        margin-left: 15px;
      }
    }

    &_link {
      font-size: 16px;
    }
  }
}

@media (max-width: $screen-md) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    top: 0;
    right: 100%;
    height: 100%;
    padding: 46px 62px 40px 63px;
    background: $black;
    max-width: 403px;
    transform: translateX(-100%);
    opacity: 1;
    transition: 0.5s;

    &.is_show {
      transition-delay: 0s;
    }

    &.is_open {
      visibility: visible;
      left: 0;
      transform: translateX(0);

      .btn_close {
        display: inline-block;
      }
    }

    &_logo {
      transform: translate(-4px, 0);
    }
  }

  .gnb {
    overflow: auto;
    width: 100%;
    margin-top: 19px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &_lst {
      flex-direction: column;
      margin-left: 0;
    }

    &_item {
      width: 100%;

      + .gnb_item {
        margin-top: 17px;
        margin-left: 0;
      }
    }

    &_link {
      padding-right: 0;
      padding-left: 0;
      font-size: 30px;
    }
  }

  .sns {
    order: 2;
    margin-left: 0;
    margin-top: auto;
    transform: translateX(-6px);

    &_item + .sns_item {
      margin-left: 8px;
    }

    .ico_youtube {
      @include sp-normal("ico_youtube_hover");
    }

    .ico_twitter {
      @include sp-normal("ico_twitter_hover");
    }

    .ico_facebook {
      @include sp-normal("ico_facebook_hover");
    }
  }

  .lnb {
    order: 1;
    width: 100%;
    margin-left: 0;

    &_select {
      display: none;
    }

    &_lst {
      display: flex;
      visibility: visible;
      position: relative;
      top: initial;
      right: initial;
      width: 100%;
      height: auto;
      margin-top: 22px;
      border: none;
      background-color: transparent;
      opacity: 1;
    }

    &_item {
      & + .lnb_item {
        margin-top: 0;
        margin-left: 14px;
      }

      &::before,
      &::after {
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        content: "";
      }

      &::before {
        top: calc(50% - 8px);
        border: 4px solid transparent;
        border-bottom-color: rgba(133, 133, 133, 0.7);
      }

      &::after {
        top: 50%;
        border: 4px solid transparent;
        border-top-color: rgba(133, 133, 133, 0.7);
      }
    }

    &_link {
      padding-left: 22px;
      padding-right: 0;
      font-family: CormorantSC;
      font-size: 22px;
      color: rgba(255, 255, 255, 0.2);
      text-transform: uppercase;

      &.is_selected{
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .menu_bars.is_show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s;
  }
}

@media (max-width: $screen-sm), (max-width:1023px) and (max-height: 767px) and (orientation: landscape){
  .header {
    max-width: initial;
    padding: 55px 17px 30px;

    .btn_close {
      left: initial;
      top: 17px;
      right: 16px;
    }
  }

  .gnb {
    margin-top: 13px;
    padding-bottom: 42px;

    &_item {
      & + .gnb_item {
        margin-top: 6px;
      }
    }

    &_link {
      font-size: 20px;
    }
  }

  .lnb {
    &_lst {
      margin-top: 10px;
    }

    &_item {
      & + .lnb_item {
        margin-left: 8px;
      }

      &::before {
        top: calc(50% - 4px);
        border: 2px solid transparent;
        border-bottom-color: #858585;
      }

      &::after {
        border: 2px solid transparent;
        border-top-color: #858585;
      }
    }

    &_link {
      padding-left: 9px;
      font-size: 15px;
    }
  }

  .menu_bars {
    top: 17px;
    left: 16px;
    padding: 8px;
  }

  .sns_item + .sns_item {
    margin-left: 0;
  }
}

.dimmed {
  display: none;
  @include absolute-full;
  z-index: map-get($z-indexes, hoverLayer);
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s;
  content: "";

  &.is_show {
    display: block;
    opacity: 1;
  }
}


@media (max-width:1023px) and (max-height: 767px) and (orientation: landscape) {
  .header {
    padding-top: 20px;
    padding-bottom: 15px;
  }

  .gnb {
    max-height: calc(100vh - 180px);
    margin-top: 8px;
    padding-bottom: 20px;

    &_link {
      padding: 4px 0;
      font-size: 17px;
    }
  }

  .lnb {
    &_lst {
      padding: 0;
    }

    &_link {
      font-size: 15px;
    }
  }
}
