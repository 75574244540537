.section_media {
  position: relative;

  .media {
    .swiper-slide {
      box-sizing: border-box;

      .btn_play {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 0;
        transform: translate(-50%, calc(-50% - 49px));
        content: "";

        @include respond-below($screen-md) {
          transform: translate(-50%, calc(-50% - 29px));
        }

        @include respond-below(720px) {
          transform: translate(-50%, calc(-50% - 5.2777vw));
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-button-prev.media_prev,
    .swiper-button-next.media_next {
      top: initial;
      bottom: 170px;
      width: 44px;
    }

    .swiper-button{
      &-prev.media_prev,
      &-prev.popup_prev,
      &-next.media_next,
      &-next.popup_next {
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          @include sp-normal('ico_arrow_right');
          font-size: 0;
          content: "";
        }
      }
    }

    .swiper-button{
      &-prev.media_prev,
      &-prev.popup_prev {
        left: 50%;
        margin-left: -588px;
        &::after {
          @include sp-normal('ico_arrow_left');
          transform: translate(-50%, -50%);
        }
      }
    }

    .swiper-button {
      &-next.media_next,
      &-next.popup_next {
        right: 50%;
        margin-right: -593px;
        &:after {
          transform: translate(-50%, -50%);
        }
      }
    }

    &_heading {
      position: absolute;
      top: 80px;
      left: 50%;
      z-index: 2;
      width: 100%;
      text-align: center;
      transform: translateX(-50%);

      @include respond-below($screen-md) {
        top: 87px;
      }

      @include respond-below(720px) {
        top: 13.4722vw;
      }
    }

    .btn_media {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      font-size: 0;
      transform: translate(-50%, calc(-50% - 47px));
      pointer-events: none;

      @include respond-below($screen-md) {
        transform: translate(-50%, calc(-50% - 29px));
      }

      @include respond-below($screen-sm) {
        transform: translate(-50%, calc(-50% - 5.2777vw));
      }

      @include respond-above(($screen-md + 1)) {
        &:hover {
          .ico_rotate_on::after{
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }

    &_title {
      opacity: 0;

      @include respond-below(720px) {

        img {
          width: 43.6111vw;
          height: 19.1666vw;
        }
      }
    }

    &_sub_title {
      margin-top: -28px;
      font-size: 20px;
      text-shadow: 0px 1px 2px #000;
      line-height: 1.1;
      letter-spacing: 0.2px;
      color: #938d83;
      text-transform: uppercase;
      opacity: 0;

      @include respond-below($screen-md) {
        font-size: 16px;
      }

      @include respond-below(720px) {
        margin: 0 auto;
        margin-top: -3.8888vw;
        font-size: 2.7777vw;
      }
    }

    &_background {
      position: absolute;
      top: 0;
      height: 100vh;
      width: 100%;
      .swiper-slide {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background: url(../img/common/media_background.png) center/cover no-repeat;
          content: "";
        }

        .img {
          width: 100vw;
        }
      }

      &.is_control .swiper-slide-active{
        .btn_media {
          pointer-events: initial;
        }
      }
    }

    &_thumbs {
      position: absolute;
      bottom: 130px;
      left: 50%;
      width: 1058px;
      transform: translateX(-50%);

      @include respond-below($screen-md) {
        bottom: 10.9375vw;
        width: 77.539vw;
        overflow: visible;
      }

      @include respond-below(720px) {
        bottom: 21.5vw;
        width: 72.9166vw;
      }

      .btn_play_sm {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 0;
        transform: translate(-50%, -50%);
      }

      .swiper-slide {
        height: 125px;
        border: 1px solid #616260;
        cursor: pointer;

        @include respond-below($screen-md) {
          height: 12.207vw;
        }

        @include respond-below(720px) {
          height: 17.3611vw;
        }

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.5);
          content: "";
        }

        &-thumb-active {
          border: 1px solid #d1c3a3;
          opacity: 1;

          &::before {
            content: initial;
          }
        }
      }
    }

    .swiper-scrollbar {
      display: none;
      bottom: -25px;
      left: 50%;
      width: 794px;
      height: 4px;
      border-radius: 0;
      background:rgba(255, 255, 255, .1);
      transform: translateX(-50%);

      @include respond-below (720px) {
        bottom: -3.19444vw;
      }

      &-drag {
        height: 3px;
        border-radius: 0;
        background: #bfa673;
        opacity: .6;
      }

      @include respond-below($screen-md) {
        display: block;
        width: 77.539vw;
      }

      @include respond-below(720px) {
        width: 72.9166vw;
        height: 3px;
        &-drag {
          height: 3px;
        }
      }

      @include respond-below(375px) {
          height: 1px;
        &-drag {
          height: 1px;
        }
      }
    }

    &_video {
      @include respond-above($screen-md + 1) {
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }
    }

    &_popup {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0,0,0,0.8);
      visibility: hidden;
      opacity: 0;
      transition: visibility .2s ease-out, opacity .2s ease-out;

      &.is_show {
        opacity: 1;
        visibility: visible;
      }

      .media_popup_slide {
        width: calc(1920px - 212px);
        height: 100vh;

        .swiper-slide {
          overflow: hidden;
          height: 100vh;

          @include respond-below(720px) {
            height: 56.25vw;
          }
        }

        @include respond-below(720px) {
            overflow: visible;
        }

        @include respond-below($screen-md) {
          &::before,
          &::after {
            position: absolute;
            z-index: 2;
            width: 100%;
            left: 0;
            background-color: rgba(0,0,0,0.9);
            content: "";

            @include respond-below(720px)  {
              content: initial;
            }
          }

          &::before {
            height: 105px;
            top: 0;
          }

          &::after {
            height: 87px;
            bottom: 0;
            background-color: rgba(0,0,0,0.6);
          }

          width: 100%;
          height: auto;

          .media_video {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            min-width: 100%;
            min-height: 100%;
            width: 177.77777778vh;
            height: 56.25vw;

            @include respond-below(720px) {
              position: static;
              min-width: initial;
              min-height: initial;
              width: initial;
              transform: translateY(0);
            }
          }
        }

        @include respond-below(720px) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: auto;
        }
      }

      .btn_close {
        position: absolute;
        z-index: 10;
        top: 29px;
        right: 29px;
        padding: 10px;
        font-size: 0;
        @include respond-below($screen-md) {
          top: 38px;
          right: 42px;
        }
        @include respond-below(720px) {
          top: 15px;
          right: 14px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      .swiper-button-next.popup_next,
      .swiper-button-prev.popup_prev {
        @include respond-below($screen-md) {
          display: none;
        }
      }

      .swiper-button {
        @include respond-below($screen-md) {
          display: none;
        }

        &-prev.popup_prev {
          margin: initial;
          top: calc(50% - 22px);
          left: 44px;
          width: 44px;
        }

        &-next.popup_next {
          margin: initial;
          top: calc(50% - 22px);
          right: 44px;
          width: 44px;
        }
      }
    }
  }
}


@media (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .section_media {
    .media {
      &_heading {
        top: 5px;

        img {
          width: auto;
          height: 75px;
        }
      }

      &_sub_title {
        margin-top: -15px;
        font-size: 14px;
      }

      &_thumbs{
        bottom: 30px;

        .swiper-slide {
          height: 10vw;
        }
      }

      .swiper-scrollbar{
        bottom: -18px;
      }

      .btn_media {
        transform: translate(-50%, calc(-50% - 35px));
      }

      &_popup {
        .btn_close {
          top: 15px;
          right: 14px;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .media_popup_slide {
          &::before,&::after {
            width: 72px;
            height: 100%;
          }

          &::after {
            left: initial;
            right: 0;
          }
        }
      }
    }
  }
}
