$retina-vars: (
	'ico_arrow_left': (
		offset-x: 0px,
		offset-y: 0px,
		width: 25px,
		height: 22px,
		total-width: 67px,
		total-height: 40px,
		imageSrc: '../img/retina.png'
	),
	'ico_arrow_right': (
		offset-x: -29px,
		offset-y: 0px,
		width: 25px,
		height: 22px,
		total-width: 67px,
		total-height: 40px,
		imageSrc: '../img/retina.png'
	),
	'ico_facebook': (
		offset-x: -58px,
		offset-y: 0px,
		width: 9px,
		height: 16px,
		total-width: 67px,
		total-height: 40px,
		imageSrc: '../img/retina.png'
	),
	'ico_twitter': (
		offset-x: 0px,
		offset-y: -26px,
		width: 18px,
		height: 14px,
		total-width: 67px,
		total-height: 40px,
		imageSrc: '../img/retina.png'
	),
	'ico_youtube': (
		offset-x: -22px,
		offset-y: -26px,
		width: 16px,
		height: 11px,
		total-width: 67px,
		total-height: 40px,
		imageSrc: '../img/retina.png'
	),
);
$retina-origin: (
  total-width: 67px,
  total-height: 40px,
  imageSrc: '../img/retina.png'
);

@mixin sprite-size($image) {
	background-size: map-get($image, 'total-width') map-get($image, 'total-height');
}

@mixin sprite-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

%set-retina {
  background-repeat: no-repeat;
  @include sprite-image($retina-origin);
  @media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {
      @include sprite-size($retina-origin);
      @include sprite-image-retina($retina-origin);
	}
}

@mixin sp-retina($image, $size: true) {
  @extend %set-retina;
	background-position: map-get(map-get($retina-vars, $image), 'offset-x') map-get(map-get($retina-vars, $image), 'offset-y');
	@if $size {
		width: map-get(map-get($retina-vars, $image), 'width');
		height: map-get(map-get($retina-vars, $image), 'height');
	}
}
