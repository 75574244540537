.section_story {
  .background {
    &::after {
      @include absolute-full;
      background-color: rgba(0, 0, 0, 0.8);
      content: "";
    }
  }

  .side_deco{
    opacity: 0;
  }
}

.map {
  &_animation {
    opacity: 0;
  }

  &_animation,
  &_main {
    @include absolute-full;
  }

  &_deco {
    @include absolute-full;

    .cloud {
      position: absolute;
      opacity: 0;

      &:nth-child(1) {
        left: 0;
        bottom: 0;
        min-width: 55.15%;
        min-height: 36.94%;
      }

      &:nth-child(2) {
        top: 0;
        left: 0;
        min-width: 69.58%;
        min-height: 100%;
      }

      &:nth-child(3) {
        top: 0;
        left: 0;
        min-width: 83.54%;
        min-height: 100%;
      }

      &:nth-child(4) {
        left: 0;
        bottom: 0;
        min-width: 67.76%;
        min-height: 63.79%;
      }

      &:nth-child(5) {
        left: 0;
        bottom: 0;
        min-width: 40.67%;
        min-height: 51.51%;
      }

      &:nth-child(6) {
        top: 0;
        left: 0;
        min-width: 52.18%;
        min-height: 100%;
      }

      &:nth-child(7) {
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
      }

      &:nth-child(8) {
        top: 0;
        right: 0;
        min-width: 94.63%;
        min-height: 100%;
      }

      &:nth-child(9) {
        right: 0;
        bottom: 0;
        min-width: 48.95%;
        min-height: 67.77%;
      }

      &:nth-child(10) {
        top: 0;
        right: 0;
        min-width: 46.45%;
        min-height: 100%;
      }
    }
  }
}

.story_area {
  position: relative;
  z-index: map-get($z-indexes, default);
  text-align: center;

  .story {
    &_head {
      text-transform: uppercase;
      text-shadow: 0px 1px 2px #000;
      opacity: 0;
    }

    &_title {
      font-size: 81px;
      font-weight: 400;
      color: #bfa673;
      line-height: 1;
    }

    &_sub_title {
      margin-top: 3px;
      font-size: 20px;
      color: #938d83;
      line-height: 1.1;
    }

    &_list {
      margin-top: 80px;

      .swiper-wrapper {
        justify-content: center;
      }

      &.is_load {
        .story_item {
          pointer-events: none;
        }
      }
    }

    &_item {
      overflow: hidden;
      width: auto;

      &[data-swiper-slide-index="0"] {
        .story_thumb {
          margin-left: auto;
        }
      }

      &[data-swiper-slide-index="1"] {
        .story_thumb {
          margin-right: auto;
        }
      }
    }

    &_coming {
      pointer-events: none;

      .story_thumb {
        &::before {
          border-color: transparent;
        }

        &::after {
          display: none;
        }
      }
    }

    &_thumb {
      display: block;
      position: relative;
      overflow: hidden;
      width: 332px;
      height: 572px;

      @include respond-above($screen-sm + 1) {
        &::before {
          @include absolute-full;
          z-index: 1;
          border: 1px solid rgba(191, 162, 138, 0.3);
          content: "";
        }
      }

      @include respond-above($screen-md + 1) {
        &::before {
          border-width: 2px;
        }

        &::after {
          @include absolute-full;
          background: url(../img/pc/story/pc_story_006.png) 100%;
          opacity: 0;
          transition: .5s;
          content: "";
        }
      }

      &:hover {
        &::after {
          opacity: 1;
        }

        .is_on + .is_off {
          opacity: 0;
        }

        .story_name {
          color: #d7c6a2;
        }

        @include respond-above(($screen-md + 1)) {
          img {
            transform: scale(1.05)
          }
        }
      }

      img {
        @include absolute-full;
        width: 100%;
        height: 100%;
        transition: 0.5s;
      }
    }

    &_ico {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &_name {
      position: absolute;
      left: 50%;
      bottom: 39px;
      font-size: 24px;
      color: #7d7154;
      line-height: 1;
      text-transform: uppercase;
      transform: translateX(-50%);
      transition: 0.5s;

      @include respond-below($screen-md) {
        color: #d7c6a2;
      }
    }

    &_next, &_prev, &_pagination {
      display: none;
      opacity: 0;
    }
  }
}

@media (min-width: ($screen-sm + 1)) {
  .story_area {
    .story {
      &_item {
        opacity: 0;
      }
    }
  }
}

@media (min-width: ($screen-md + 1)) {
  .story_area {
    .story {
      &_thumb {
        &:hover {
          .ico_rotate {
            @include sp-normal("ico_rotate_on");

            &::after {
              background-image: url(../img/common/ico_plus_on.png);
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }
        }
      }
    }
  }
}

@media (max-height: 900px),(max-width: $screen-md) {
  .story_area {
    .story {
      &_list {
        margin-top: 66px;
      }

      &_title {
        font-size: 62px;
      }

      &_sub_title {
        font-size: 16px;
        line-height: 1.13;
      }

      &_thumb {
        width: 206px;
        height: 354px;
      }

      &_name {
        bottom: 20px;
        font-size: 18px;
      }
    }
  }
}

@media (max-height: 900px) and (min-width: ($screen-md + 1)) {
  .story_area {
    .story {
      &_name {
        bottom: 24px;
        font-size: 15px;
      }
    }
  }
}

@media (max-width: $screen-md) {
  .story_area {
    padding-bottom: 25px;
  }
}

@media (max-width: $screen-sm) {
  .story_area {
    padding-bottom: 28px;

    .story {
      &_title {
        font-size: 10.83vw;
      }

      &_sub_title {
        margin-top: 0;
        font-size: 2.77vw;
      }

      &_list {
        overflow: hidden;
        max-width: 78.88vw;
        margin: 10vw auto 0;
        opacity: 0;

        .swiper-wrapper {
          justify-content: flex-start;
        }
      }

      &_thumb {
        width: 78.88vw;
        height: 106.66vw;
        margin: 0 auto;

        img {
          width: 100%;
        }

        &::after {
          display: none;
        }
      }

      &_next, &_prev {
        display: inline-block;
        top: initial;
        bottom: -40px;
        width: 44px;
        font-size: 0;
      }

      &_next {
        right: initial;
        left: calc(50% + 119px);
      }

      &_prev {
        left: calc(50% - 163px);
      }

      &_pagination {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: -38px;
        left: 50%;
        transform: translateX(-50%);
      }

      &_name{
        display: none;
      }
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 36px;
      height: 40px;
      padding: 11px;
      margin: 0 !important;
      background: none;
      font-size: 0;
      opacity: 1;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        width: 12px;
        height: 1px;
        margin: 0;
        background-color: rgba(191, 162, 138, 0.3);
        transform: translateY(-50%);
        content: "";
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }

      &:first-child {
        &::before {
          opacity: 0;
        }
      }

      &:last-child {
        &::after {
          opacity: 0;
        }
      }

      &-active {
        .ico_pagination {
          @include sp-normal("ico_pagination_small_on");
        }
      }
    }
  }
}

@media (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .story_area {
    padding-bottom: 0;

    .story {
      &_title {
        font-size: 32px;
      }

      &_sub_title {
        font-size: 14px;
      }

      &_list {
        margin-top: 9vh;
      }

      &_thumb {
        width: 35vh;
        height: 47.324vh;

        &::before, &::after {
          display: none;
        }

        &:hover {
          .ico_rotate {
            width: 14vh;
            height: 14vh;
            background-image: url(../img/common/ico_rotate_mo.png);
            background-size: 100%;
            background-position: 0;
          }
        }
      }

      &_name {
        display: none;
      }
    }
  }
}
