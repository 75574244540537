@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpZ {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoomInFadeOut {
  from {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
  }

  40% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 0.5;
    transform: scale3d(2, 2, 2);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeInOut
{
  0%{
    opacity:0;
  }
  75%
  {
    opacity:1;
  }
  100%
  {
    opacity:0;
  }
}

// Intro
.section_intro.active {
  .title {
    animation: fadeInOut 6s 2s ease forwards;
  }
}

// KeyVisual
.section_key_visual.active {
  .title {
    animation: fadeInDown 1s 0.5s cubic-bezier(0.76, 0.57, 0.2, 0.97) both;
  }

  .title_sub {
    animation: fadeInUp 1s 1.5s both;
  }
}

// World view
.section_world_view.active .swiper-slide-active {
  .category {
    animation: fadeInLeft 1s 0.25s both;
  }

  .title {
    animation: fadeInLeft 1s 0.5s both;
  }

  .desc .delay12 {
    animation: fadeInUp 1.2s 1.2s both;
  }

  .desc .delay16 {
    animation: fadeInUp 1.2s 1.6s both;
  }

  .desc .delay20 {
    animation: fadeInUp 1.2s 2s both;
  }

  .desc .delay24 {
    animation: fadeInUp 1.2s 2.4s both;
  }

  .desc .delay28 {
    animation: fadeInUp 1.2s 2.8s both;
  }
}

@keyframes cloudOutRight {
  100% {
    opacity: 0;
    transform: translateX(25%);
  }
}

@keyframes cloudOutLeft {
  100% {
    opacity: 0;
    transform: translateX(-25%);
  }
}

@keyframes cloudOutDown {
  100% {
    opacity: 0;
    transform: translateY(25%);
  }
}

@keyframes cloudOut {
  100% {
    opacity: 0;
  }
}

// Story
.section_story.active {
  .background::after {
    animation: fadeOut 4s 0.5s ease forwards;
  }

  .map {
    &_animation {
      animation: zoomOut 4.5s .5s ease forwards;
    }

    &_deco {
      .cloud {
        opacity: 1;

        &:nth-child(1),&:nth-child(4),&:nth-child(5) {
          animation: cloudOutDown 4s .5s forwards;
        }

        &:nth-child(7),&:nth-child(8) {
          animation: cloudOut 4s .5s forwards;
        }

        &:nth-child(2),&:nth-child(3),&:nth-child(6) {
          animation: cloudOutLeft 4s .5s forwards;
        }

        &:nth-child(9),&:nth-child(10) {
          animation: cloudOutRight 4s .5s forwards;
        }
      }
    }
  }

  .side_deco {
    animation: fadeIn 1s 4.5s forwards;
  }

  .story_head {
    animation: fadeIn 1s 4.5s forwards;
  }
}

@media (min-width: ($screen-sm + 1)) {
  .section_story.active .story_item{
    &[data-swiper-slide-index="0"] {
      animation: fadeInUpZ 1s 5s both;
    }

    &[data-swiper-slide-index="1"] {
      animation: fadeInUpZ 1s 5.5s both;
    }
  }
}

@media (max-width: $screen-sm) {
  .section_story.active {
    .story_list {
      animation: fadeInUpZ 1s 5s forwards;
    }

    .story_next, .story_prev, .story_pagination {
      animation: fadeIn 1s 5s forwards;
    }
  }
}

// House
.section_house.active .swiper-slide-active {
  .house {
    &_category {
      display: inline-block;
      animation: fadeInLeft 1s 0.25s both;
    }

    &_title {
      animation: fadeInLeft 1s 0.5s both;
    }

    &_character {
      animation: fadeInRight 1s 1s cubic-bezier(0.5, 0.48, 0.16, 0.96) both;
    }
  }

  .btn_story {
    animation: fadeInLeft 1s 0.75s both;
  }
}

// Combat
@keyframes unfoldIn {
  0% {
    transform: scaleY(0) scaleX(0);
  }
  10% {
    transform: scaleY(0) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0) scaleX(1);
  }
  100% {
    transform: scaleY(0) scaleX(0);
  }
}

.section_combat.active {
  .combat_item.swiper-slide-active .combat_img_box {
    transform: scale(1);
  }

  .combat_title {
    animation: fadeInUp 1s .5s both;
  }

  .combat_sub_title {
    animation: fadeInUp 1s .6s both;
  }

  .combat_card {
    &[data-index="1"] {
      animation: fadeInUp 1s .75s backwards;
    }
    &[data-index="2"] {
      animation: fadeInUp 1s 1s backwards;
    }
    &[data-index="3"] {
      animation: fadeInUp 1s 1.25s backwards;
    }
  }

  .combat_popup.is_show {

    .combat_popup_title span{
      animation: fadeInUp 1.2s .5s both;
    }
    .combat_popup_desc {
      &:first-child {
        animation: fadeInUp 1.2s .75s both;
      }
      &:nth-child(2) {
        animation: fadeInUp 1.2s 1s both;
      }
      &:nth-child(3) {
        animation: fadeInUp 1.2s 1.25s both;
      }
    }
  }
}

// Media
.section_media.active {
  .media{
    &_title {
      animation: fadeInUp 1s .5s both;
    }

    &_sub_title {
      animation: fadeInUp 1s .6s both;
    }
  }
}
