html:lang(ko) {
  $NotoSerifKR-font: NotoSerifKR, sans-serif;
  $NanumMyeongjo-font: NanumMyeongjo, sans-serif;

  // World view
  .world_view_content {

    @include respond-above($screen-md + 1) {
      transform: translateY(26.4%);
      .title {
        margin-top: 16px;
      }
    }

    .desc {
      font-family: $NotoSerifKR-font;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.32px;
      @include respond-below($screen-md) {
        margin-top: 7px;
      }
      @include respond-below($screen-sm) {
        margin-top: 2.777vw;
        font-size: 3.333vw;
        line-height: 1.5;
      }
    }

    .world_view_prev,
    .world_view_next {
      bottom: -83px;

      @include respond-below($screen-md) {
        bottom: -69px;
      }

      @include respond-below($screen-sm) {
        bottom: -43px;
      }
    }

    .world_view_pagination {
      @include respond-above($screen-md + 1) {
        bottom: -90px;
      }

      @include respond-below($screen-md) {
        bottom: -71px;
      }

      @include respond-below($screen-sm) {
        bottom: -45px;
      }
    }

    .title {
      @include respond-below($screen-md) {
        margin-top: 9px;
      }
      @include respond-below($screen-md) {
        margin-top: 2.222vw;
      }
    }

    @include respond-between($screen-sm + 1, $screen-md) {
      bottom: 108px;
      .category {
        padding-left: 5px;
        font-size: 18px;
      }
    }

    @include respond-below($screen-sm) {
      bottom: 20.416vw;
      font-size: 3.333vw;
      line-height: 1.5;
      .category {
        font-size: 3.333vw;
      }
      .title_crisis {
        .img {
          width: 71.666vw;
        }
      }

      .title_chaos {
        .img {
          width: 63.472vw;
        }
      }

      .title_evils {
        .img {
          width: 52.777vw;
        }
      }

      .title_war {
        .img {
          width: 51.111vw;
        }
      }

      .title_fight {
        .img {
          width: 64.305vw;
        }
      }
    }
  }

  // Story
  .story {
    &_area {
      .story_sub_title {
        font-family: $NanumMyeongjo-font;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.333vw;

        @include respond-below($screen-md) {
          font-size: 1.562vw;
          line-height: 1.11;
        }

        @include respond-below($screen-sm) {
          font-size: 3.055vw;
          line-height: 1.45;
          letter-spacing: 0.888vw;
        }
      }
    }

    &_name {
      font-family: $NanumMyeongjo-font;
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;

      @media (max-height: 900px),(max-width: $screen-md) {
        bottom: 22px;
        font-size: 14px;
        line-height: 1;
      }

      @media (max-height: 900px) and (min-width: ($screen-md + 1)) {
        bottom: 27px;
        font-size: 13px;
      }

      @include respond-below($screen-sm) {
        display: none;
      }
    }
  }

  // House
  .house {
    @mixin heading-style() {
      font-family: $NanumMyeongjo-font;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.333vw;
    }

    &_category {
      @include heading-style();

      @include respond-below($screen-sm) {
        font-size: 3.888vw;
        letter-spacing: 1.11vw;
      }
    }

    &_popup {
      .story {
        &_pop_name {
          position: absolute;
          top: -35px;
          left: 50%;
          font-family: $NanumMyeongjo-font;
          font-size: 18px;
          line-height: 1;
          letter-spacing: 0.333vw;
          transform: translateX(-50%);
          color: #bfa673;

          @include respond-below($screen-md) {
            top: -30px;
          }

          @include respond-below($screen-sm) {
            top: -4.861vw;
            left: 7.63vw;
            font-size: 3.333vw;
            letter-spacing: 0.888vw;
            transform: translateX(0);
          }
        }

        &_box_aerius {
          @include respond-between($screen-sm + 1, $screen-md) {
            right: 10.37vw;
            transform: translateY(calc(-50% + 15px));

            &:after {
              bottom: -6.933vw;
            }
          }

          @include respond-above($screen-md + 1) {
            &:after {
              bottom: -52px;
            }
          }

          @include respond-below($screen-sm) {
            &:before {
              left: 15vw;
            }
          }
        }

        &_content {
          margin-top: 7.33vw;

          @include respond-between($screen-sm + 1, $screen-md) {
            margin-top: 3.33vw;
          }
        }

        &_sub_title {
          @include heading-style();

          @include respond-below($screen-md) {
            font-size: 16px;
            letter-spacing: 0.625vw;
          }

          @include respond-below($screen-sm) {
            margin-top: 2.11vw;
            font-size: 3.333vw;
            line-height: 1.16;
            letter-spacing: 0.577vw;
          }
        }

        &_desc {
          font-family: $NotoSerifKR-font;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.32px;

          @include respond-below($screen-sm) {
            font-size: 3.333vw;
            line-height: 1.5;
            letter-spacing: 0.044vw;

            .only_tablet,
            .above_tablet {
              display: none;
            }
          }

          @include respond-above($screen-sm + 1) {
            .only_mo {
              display: none;
            }
          }

          &:not(:first-child) {
            @include respond-below($screen-sm) {
              margin-top: 5.166vw;
            }
          }
        }

        @include respond-above($screen-md + 1) {
          &_box {
            transform: translateY(calc(-50% - 16px));
          }

          &_box_grandal {
            right: 19.791vw;
          }

          &_content {
            margin-top: 53px;
          }
        }

        &_scrollbar {
          @include respond-above(1920px) {
            right: 264px;
            transform: translateY(-72px);
          }
        }
      }
    }
  }

  // Combat
  .combat {
    &_sub_title {
      font-family: $NotoSerifKR-font;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.333vw;

      @include respond-below($screen-md) {
        font-size: 16px;
        line-height: 1.11;
      }

      @include respond-below($screen-sm) {
        font-size: 3.055vw;
        line-height: 1.45;
        letter-spacing: 0.888vw;
      }
    }
    &_popup_content {
      bottom: 10.648vh;

      @include respond-below($screen-md) {
        bottom: 15.234vh;
      }

      @include respond-below($screen-sm) {
        bottom: 11.805vw;
      }
    }
    &_popup_title {
      @include respond-below($screen-sm) {
        bottom: 43.98vh;
      }
      span {
        font-family: $NanumMyeongjo-font;
        @include vw-convert-pc(font-size, 65);
        line-height: 78px;
        letter-spacing: -0.8px;

        @include respond-below($screen-md) {
          font-size: 48px;
          line-height: 1;
          letter-spacing: 0;
        }

        @include respond-below($screen-sm) {
          font-size: 11.111vw;
          line-height: 1.125;
          letter-spacing: -0.177vw;
        }
      }
    }

    &_popup_desc {
      font-family: $NotoSerifKR-font;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.32px;

      @include respond-below($screen-sm) {
        font-size: 3.333vw;
        line-height: 1.5;
        letter-spacing: 0.044vw;

        &:not(:first-child) {
          margin-top: 5.416vw;
        }
      }
    }

    &_popup_sub {
      font-size: 16px;
      line-height: 56px;
      letter-spacing: 0.333vw;
      text-transform: uppercase;

      @include respond-below($screen-md) {
        margin-top: 16px;
        line-height: 1.375;
        letter-spacing: 0.625vw;
      }

      @include respond-below($screen-sm) {
        margin-top: 0.833vw;
        font-size: 2.222vw;
        line-height: 1.5;
        letter-spacing: 0.888vw;
      }
    }
  }

  // Media
  .media {
    &_sub_title {
      font-family: $NotoSerifKR-font;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.333vw;

      @include respond-below($screen-md) {
        font-size: 16px;
        line-height: 1.1;
      }

      @include respond-below($screen-sm) {
        font-size: 3.055vw;
        line-height: 1;
        letter-spacing: 0.8vw;
      }
    }
  }

  // Footer
  .footer {
    &_inner {
      font-family: $NotoSerifKR-font;
      letter-spacing: 0.8px;
    }

    &_txt {
      font-size: 10px;
      line-height: 2.7;
    }

    &_item {
      font-size: 12px;
      line-height: 20px;
    }
  }

  // Animate
  .section_combat.active {
    .combat_popup.is_show {
      .combat_popup_sub {
        animation: fadeInUp 1.2s 0.7s backwards;
      }
    }
  }

  // Icon
  .ico_read_story {
    @include sp-normal("ico_read_story_kr");

    @include respond-below($screen-md) {
      @include sp-normal("ico_read_story_ta_kr");
    }
  }

  @include respond-below($screen-sm) {
    .ico_read_story {
      @include background-full();
      width: 26.805vw;
      height: 4.305vw;
      background-image: url(../img/common/ico_read_story_kr_mo.png);
    }
  }

  @media (max-width: 1023px) and (max-height: 767px) and (orientation: landscape) {
    .world_view_content .category {
      font-size: 15px;
    }

    .world_view_content .title .img {
      width: auto;
      height: 30.5px;
    }

    .world_view_content .desc {
      font-size: 15px;
    }

    .world_view_content {
      bottom: 12.416vw;
    }

    .story_area .story_sub_title {
      font-size: 14px;
    }

    .combat_sub_title {
      font-size: 13px;
    }

    .media_sub_title {
      font-size: 14px;
    }
  }
}
