// Section Intro
.section_intro {
  .background {
    &::after {
      @include absolute-full;
      background-image: url(../img/pc/intro/pc_intro_001.png);
      background-position: center;
      background-size: cover;
      content: "";
    }
  }

  .btn_skip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 42px;
    font-size: 0;
  }

  .box_content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title {
    opacity: 0;
    width: 38.333vw;
    max-width: 878px;

    .img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: $screen-md) {
  .section_intro {
    .title {
      width: 494px;
    }

    .btn_skip {
      bottom: 45px;
      margin-left: 11px;
    }
  }
}

@media (max-width: $screen-sm), (max-width:1023px) and (max-height:767px) and (orientation:landscape){
  .section_intro .title {
    width: 300px;
  }
}
