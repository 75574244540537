.section_footer {
  @include vw-convert-pc(padding-top, 104);
  @include vw-convert-pc(padding-bottom, 104);
  background-color: #000;
  box-sizing: border-box;

  @include respond-below($screen-md) {
    padding: 8.398vw 0;
  }

  @include respond-below($screen-sm) {
    padding: 9.166vw 0;
  }
}

.footer {
  &_inner {
    @include vw-convert-pc(padding-left, 257);
    @include vw-convert-pc(padding-right, 257);
    font-family: beaufort-pro;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.31);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.7px;
    color: rgba(191, 166, 115, 0.75);
    box-sizing: border-box;

    @include respond-below($screen-md) {
      padding: 0 10.839vw;
    }
    @include respond-below($screen-sm) {
      padding: 0 6.944vw;
    }
  }
  
  &_list {
    @include respond-above(992px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &_item {
    @include respond-above(992px) {
      &:not(:first-child):not(:last-child) {
        margin-left: 10px;
      }
      .fax {
        margin-left: 7px;
      }
    }

    &:last-child {
      width: 100%;
    }

    @include respond-below($screen-sm) {
      line-height: 1.75;
    }
  }

  &_noti {
    margin-top: 10px;
  }

  &_logo {
    display: inline-block;
    font-size: 0;
  }

  &_txt {
    font-size: 11.5px;
    font-weight: 400;
    letter-spacing: 0.58px;
    color:rgba(112, 107, 99, 0.75);
  }

  &_bottom {
    @include vw-convert-pc(margin-top, 32);
    @include vw-convert-pc(padding-top, 34);
    border-top: 1px solid rgba(112, 107, 99, 0.2);

    @include respond-below($screen-md) {
      margin-top: 3.808vw;
      padding-top: 3.906vw;
    }

    @include respond-below($screen-sm) {
      margin-top: 6.666vw;
      padding-top: 6.666vw;
    }
  }

  &_copyright {
    @include respond-above($screen-sm + 1) {
      margin-left: 10px;
    }
  }
}
