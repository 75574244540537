// Section World view
.world_view_content {
  position: absolute;
  left: 0;
  bottom: 50%;
  z-index: map-get($z-indexes, default);
  width: 100%;
  padding-left: 18.43vw;
  transform: translateY(28.4%);

  .swiper-wrapper {
    align-items: center;
  }

  .category {
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    color: #706b63;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 12px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.31);
    opacity: 0;

    @media (min-width: ($screen-md + 1)) {
      padding-left: 4px;
    }
  }

  .title {
    margin-top: 19px;
    opacity: 0;

    @media (min-width: ($screen-md + 1)) {
      margin-left: -3px;
    }
  }

  .desc {
    margin-top: 20px;
    font-family: beaufort-pro;
    font-size: 18px;
    color: #a19c92;
    line-height: 1.31;
    letter-spacing: 0.36px;
    pointer-events: none;

    span {
      display: inline-block;
      opacity: 0;
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
  }

  .world_view_pagination {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -71px;
    left: calc(18vw + 52px);
    width: auto;
    margin-left: -17px;
  }

  .swiper-pagination-bullet {
    position: relative;
    width: 57px;
    height: 40px;
    padding: 11px;
    margin: 0 !important;
    background: none;
    font-size: 0;
    opacity: 1;

    @include respond-above($screen-md + 1) {
      width: 80px;
      height: 48px;
      padding: 11px;
    }
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      width: 20px;
      height: 1px;
      margin: 0;
      background-color: rgba(191, 162, 138, 0.3);
      transform: translateY(-50%);
      content: "";

      @include respond-above($screen-md + 1) {
        width: 28px;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &:first-child {
      &::before {
        opacity: 0;
      }
    }

    &:last-child {
      &::after {
        opacity: 0;
      }
    }
  }

  .world_view {
    &_prev,
    &_next {
      display: inline-block;
      top: initial;
      bottom: -64px;
      margin-top: 0;
      width: 36px;
      height: 36px;
      font-size: 0;
    }

    &_prev {
      left: 18.43vw;

      .ico_arrow_left {
        @media (min-width: ($screen-md + 1)) {
          @include sp-normal('ico_arrow_left');
        }
      }
    }

    &_next {
      left: calc(18.43vw + 415px);

      .ico_arrow_right {
        @media (min-width: ($screen-md + 1)) {
          @include sp-normal('ico_arrow_right');
        }
      }
    }
  }
}

@media (max-width: $screen-lg) {
  .world_view_content {
    padding-left: 22vw;

    .category {
      font-size: 22px;
      letter-spacing: 0.5em;
    }

    .title {
      margin-top: 17px;
    }

    .desc {
      margin-top: 15px;
      font-size: 16.5px;
      line-height: 1.36;
      letter-spacing: 0.33px;
    }

    .world_view_pagination {
      left: calc(22vw + 52px);
    }

    .world_view {
      &_prev {
        left: 22vw;
      }

      &_next {
        left: calc(22vw + 430px);
      }
    }
  }
}

@media (max-width: $screen-md) {
  .world_view_content {
    bottom: 100px;
    padding-left: 0;
    transform: translateY(0);

    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;
      text-align: center;
    }

    .title{
      .img{
        margin: 0 auto;
      }
    }

    .world_view_pagination {
      bottom: -63px;
      left: 0;
      width: 100%;
      margin-left: 0;
      justify-content: center;
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
  .world_view_content {
    .world_view {
      &_prev,
      &_next {
        bottom: -61px;
      }

      &_next {
        left: calc(50% + 144px);
      }

      &_prev {
        left: calc(50% - 180px);
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .world_view_content {
    bottom: 76px;

    .swiper-wrapper {
      align-items: flex-start;
    }

    .category {
      font-size: 3.33vw;
    }

    .title {
      margin-top: 1.94vw;

      &_crisis{
        .img{
          width: 53.47vw;
        }
      }

      &_chaos{
        .img{
          width: 80vw;
        }
      }

      &_evils{
        .img{
          width: 60.55vw;
        }
      }

      &_war{
        .img{
          width: 82.08vw;
        }
      }

      &_fight{
        .img{
          width: 61.38vw;
        }
      }
    }

    .desc {
      margin-top: 1.66vw;
      font-size: 3.33vw;
      line-height: 1.25;
      letter-spacing: normal;
    }

    .swiper-pagination-bullet {
      width: 36px;

      &::before,
      &::after {
        width: 12px;
      }
    }

    .world_view_pagination {
      bottom: -48px;
    }

    .world_view {
      &_prev,
      &_next {
        bottom: -46px;
      }

      &_next {
        left: calc(50% + 124px);
      }

      &_prev {
        left: calc(50% - 160px);
      }
    }
  }
}

// Indicator
.indicator {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 2.968vw;
  z-index: map-get($z-indexes, dropdown);
  width: 56px;
  height: 207px;
  background: url(../img/common/indicator.png) 100%;
  transform: translateY(-50%);
  opacity: 0;

  &.is_show {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s;
  }

  &_btn_prev,
  &_btn_next {
    position: absolute;
    width: 100%;
    height: 46px;
  }

  &_btn_prev {
    top: -23px;
  }

  &_btn_next {
    bottom: -23px;
  }

  &_lst {
    position: absolute;
    width: 120px;
    height: 32px;
    top: 50%;
    left: calc(100% + 11px);
    transform: translateY(-50%);
  }

  &_item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &_text {
    display: block;
    font-size: 15.5px;
    color: #ceb98e;
    line-height: 2.32;
    text-transform: uppercase;
    letter-spacing: 1.55px;
    opacity: 0;
    transition: 0.7s;

    &.is_show, &.is_selected {
      opacity: 1;
    }
  }
}

@media (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .world_view_content {
    bottom: 72px;

    .category {
      font-size: 15px;
    }

    .title {
      margin-top: 13px;
    }

    .title_evils .img,.title_chaos .img {
      width: auto;
      height: 59px;
    }

    .title_crisis .img, .title_war .img, .title_fight .img {
      width: auto;
      height: 30.5px;
    }

    .desc {
      margin-top: 10px;
      font-size: 15px;
    }

    .world_view {
      &_prev,
      &_next {
        bottom: -48px;
      }
    }

    .world_view_pagination {
      bottom: -50px;
    }

    .swiper-pagination-bullet {
      width: 36px;

      &::after, &::before {
        width: 13px;
      }
    }
  }

  .indicator {
    left: 20px;
  }
}

@media (max-width: $screen-lg) {
  .indicator {
    left: 39px;
  }
}
