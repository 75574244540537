.ico_skip {
  @include sp-normal("ico_skip");
}

.ico_logo {
  @include sp-normal("logo");
}

.ico_youtube {
  @include sp-normal("ico_youtube");
}

.ico_twitter {
  @include sp-normal("ico_twitter");
}

.ico_facebook {
  @include sp-normal("ico_facebook");
}

.ico_youtube_mo {
  @include sp-retina("ico_youtube");
}

.ico_twitter_mo {
  @include sp-retina("ico_twitter");
}

.ico_facebook_mo {
  @include sp-retina("ico_facebook");
}

.ico_earth {
  @include sp-normal("ico_earth");
}

.ico_pagination {
  @include sp-normal("ico_pagination");
}

.world_view_content .swiper-pagination-bullet-active .ico_pagination {
  @include sp-normal("ico_pagination_on");
}

.ico_bars {
  @include sp-normal("ico_bars");
}

.ico_close {
  @include sp-normal("ico_close_sm");
}

.ico_close_mo {
  width: 26.5px;
  height: 27px;
  background-image: url(../img/common/ico_close_mo.png);
  @include background-full();
}

.ico_close_sm {
  @include sp-normal("ico_close_sm");
}

.ico_arrow_right {
  @include sp-retina("ico_arrow_right");
}

.ico_arrow_left {
  @include sp-retina("ico_arrow_left");
}

.ico_read_story {
  @include sp-normal("ico_read_story");
}

.ico_grendal {
  background-image: url(../img/common/ico_grendal.png);
}

.ico_guntfell {
  background-image: url(../img/common/ico_guntfell.png);
}

.ico_erhard {
  background-image: url(../img/common/ico_erhard.png);
}

.ico_collin {
  background-image: url(../img/common/ico_collin.png);
}

.ico_aindal {
  background-image: url(../img/common/ico_aindal.png);
}

.ico_aerius {
  background-image: url(../img/common/ico_aerius.png);
}

.ico_back {
  @include sp-normal("ico_back");
}

.ico_rotate {
  position: relative;
  @include sp-normal("ico_rotate");

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background: url(../img/common/ico_plus.png) 100%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    content: "";
  }
}

.ico_rotate_on {
  position: relative;
  @include sp-normal("ico_rotate_on");

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background: url(../img/common/ico_plus_on.png) 100%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    content: "";
  }
}

.swiper-pagination-bullet-active {
  .ico_grendal {
    background-image: url(../img/common/ico_grendal_active.png);
  }

  .ico_guntfell {
    background-image: url(../img/common/ico_guntfell_active.png);
  }

  .ico_erhard {
    background-image: url(../img/common/ico_erhard_active.png);
  }

  .ico_collin {
    background-image: url(../img/common/ico_collin_active.png);
  }

  .ico_aindal {
    background-image: url(../img/common/ico_aindal_active.png);
  }

  .ico_aerius {
    background-image: url(../img/common/ico_aerius_active.png);
  }
}

.ico_play {
  position: relative;
  @include sp-normal("ico_play");

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    @include sp-normal("ico_rec");
    transform: translate(-33%, -50%);
    content: "";
  }

  @include respond-below($screen-md) {
    @include sp-normal("ico_play_ta");

    &::after {
      @include sp-normal("ico_rec_ta");
    }
  }

  @include respond-below($screen-sm) {
    width: 55px;
    height: 55px;
    background-image: url(../img/common/ico_play.png);
    @include background-full();


    &::after {
      width: 23px;
      height: 32px;
      background-image: url(../img/common/ico_rec.png);
      @include background-full();
    }
  }

  &_sm {
    position: relative;
    width: 64px;
    height: 64px;
    background-image: url(../img/common/ico_play.png);
    background-size: 100% 100%;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 23px;
      height: 32px;
      background-image: url(../img/common/ico_rec.png);
      background-size: 100% 100%;
      transform: translate(-33%, -50%);
      content: "";
    }

    @include respond-below($screen-md) {
      width: 55px;
      height: 55px;

      &::after {
        width: 19.76px;
        height: 27.5px;
      }
    }

    @include respond-below($screen-md) {
      width: 32px;
      height: 32px;

      &::after {
        width: 11.5px;
        height: 16px;
      }
    }
  }
}

.ico_brand {
  @include sp-normal("ico_brand");
}

@media (max-width: $screen-md) {
  .ico_logo {
    @include sp-normal("logo_ta");
  }

  .ico_skip {
    @include sp-normal("ico_skip_ta");
  }

  .ico_read_story {
    @include sp-normal("ico_read_story_ta");
  }

  .ico_back {
    @include sp-normal("ico_back_ta");
  }

  .ico_rotate, .ico_rotate_on {
    @include sp-normal("ico_rotate_ta");

    &::after {
      width: 32px;
      height: 32px;
      background: url(../img/common/ico_plus_ta.png) 100%;
    }
  }

  .ico_pagination {
    @include sp-normal("ico_pagination_ta");
  }

  .world_view_content .swiper-pagination-bullet-active .ico_pagination {
    @include sp-normal("ico_pagination_ta_on");
  }
}

@media (max-width: $screen-sm), (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .ico_logo {
    width: 179.5px;
    height: 37px;
    background-image: url(../img/common/logo_ta.png);
    @include background-full();
  }

  .ico_bars {
    width: 28px;
    height: 22px;
    background-image: url(../img/common/ico_navi.png);
    @include background-full();
  }

  .ico_pagination {
    @include sp-normal("ico_pagination_small");
  }

  .world_view_content .swiper-pagination-bullet-active .ico_pagination {
    @include sp-normal("ico_pagination_small_on");
  }

  .ico_read_story {
    width: 35.27vw;
    height: 3.88vw;
    background-image: url(../img/common/ico_read_story_mo.png);
    @include background-full();
  }

  .ico_rotate {
    width: 18.47vw;
    height: 18.61vw;
    background-image: url(../img/common/ico_rotate_mo.png);
    @include background-full();

    &::after {
      width: 8.19vw;
      height: 8.19vw;
      background: url(../img/common/ico_plus_mo.png);
      @include background-full();
    }
  }

  .ico_rotate_on {
    width: 67px;
    height: 67px;
    background: url(../img/common/ico_rotate_mo.png);
    @include background-full();

    &::after {
      width: 30px;
      height: 30px;
      background: url(../img/common/ico_plus_on.png) 100%;
      @include background-full();
    }
  }

  .ico_back {
    width: 35px;
    height: 24.5px;
    background: url(../img/common/ico_back.png);
    @include background-full();
  }

  .ico {
    &_armorial {
      width: 41.5px;
      height: 41.5px;
      @include background-full();
    }

    &_grendal {
      background-image: url(../img/common/ico_grendal.png);
    }

    &_guntfell {
      background-image: url(../img/common/ico_guntfell.png);
    }

    &_erhard {
      background-image: url(../img/common/ico_erhard.png);
    }

    &_collin {
      background-image: url(../img/common/ico_collin.png);
    }

    &_aindal {
      background-image: url(../img/common/ico_aindal.png);
    }

    &_aerius {
      background-image: url(../img/common/ico_aerius.png);
    }
  }

  .swiper-slide-active {
    .ico {
      &_armorial {
        width: 60px;
        height: 59.5px;
      }

      &_grendal {
        background-image: url(../img/common/ico_grendal_active.png);
      }

      &_guntfell {
        background-image: url(../img/common/ico_guntfell_active.png);
      }

      &_erhard {
        background-image: url(../img/common/ico_erhard_active.png);
      }

      &_collin {
        background-image: url(../img/common/ico_collin_active.png);
      }

      &_aindal {
        background-image: url(../img/common/ico_aindal_active.png);
      }

      &_aerius {
        background-image: url(../img/common/ico_aerius_active.png);
      }
    }
  }

  .ico_close_sm {
    width: 26.5px;
    height: 27px;
    background-image: url(../img/common/ico_close_mo.png);
    @include background-full();
  }

  .ico_brand {
    @include sp-normal("ico_brand_mo");
  }
}

@media (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .ico_rotate {
    width: 14vh;
    height: 14vh;

    &::after {
      width: 6vh;
      height: 6vh;
    }
  }

  .ico_read_story {
    width: 101.6px;
    height: 11.2px;
  }
}
