/* CormorantSC */
@font-face {
  font-family: 'CormorantSC';
  font-weight: 300;
  src: url(../font/CormorantSC-Light.woff) format('woff');
}

@font-face {
  font-family: 'CormorantSC';
  font-weight: 400;
  src: url(../font/CormorantSC-Regular.woff) format('woff');
}

@font-face {
  font-family: 'CormorantSC';
  font-weight: 500;
  src: url(../font/CormorantSC-Medium.woff) format('woff');
}

@font-face {
  font-family: 'CormorantSC';
  font-weight: 600;
  src: url(../font/CormorantSC-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'CormorantSC';
  font-weight: 700;
  src: url(../font/CormorantSC-Bold.woff) format('woff');
}

/* NotoSerifKR */
@font-face {
  font-family: 'NotoSerifKR';
  src: url(../font/NotoSerifKR-Regular.otf) format('opentype');
  font-style: normal;
}

/* NanumMyeongjo */
@font-face {
  font-family: 'NanumMyeongjo';
  src: url(../font/NanumMyeongjo-Regular.woff) format('woff');
}

@font-face {
  font-family: 'NanumMyeongjo';
  font-weight: 700;
  src: url(../font/NanumMyeongjo-Bold.woff) format('woff');
}

