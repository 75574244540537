// House
.section_house {
  .btn_back {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: map-get($z-indexes, default);
    padding: 10px;
    font-size: 0;
  }
}

.house {
  &_character {
    position: absolute;
    opacity: 0;

    @include respond-above(($screen-sm + 1)) {
      bottom: 0;
    }
  }

  &_grendal {
    .house_character {
      left: 42.3%;
      height: 92.5vh;
    }
  }

  &_guntfell {
    .house_character {
      left: 42.3%;
      height: 92.3vh;
    }
  }

  &_erhard {
    .house_character {
      left: 44.2%;
      height: 91.3vh;
    }
  }

  &_collin {
    .house_character {
      left: 38.4%;
      height: 92.2vh;
    }
  }

  &_aindal {
    .house_character {
      left: 45.1%;
      height: 90.5vh;
    }
  }

  &_aerius {
    .house_character {
      left: 42.1%;
      height: 100%;
    }
  }

  &_content {
    position: absolute;
    top: 50%;
    left: 0;
    @include vw-convert-pc("padding-left", 357);
    z-index: 1;
    width: 100%;
    transform: translateY(-96%);
    box-sizing: border-box;

    .content {
      .swiper-wrapper {
        align-items: flex-end;
      }

      .swiper-slide {
        &:not(.swiper-slide-active) {
          opacity: 0 !important;
        }
      }
    }

    .btn_story {
      font-size: 0;
      opacity: 0;
    }
  }

  &_category {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 12px;
    text-transform: uppercase;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.31);
    color: #706b63;
    opacity: 0;
  }

  &_title {
    margin: 19px 0 19px -3px;
    opacity: 0;
  }

  &_content {
    .house_pagination {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: -118px;
      @include vw-convert-pc("left", 357);
      height: 76px;
      z-index: 1;

      i {
        width: 54px;
        height: 54px;
        @include background-full();
        transition: .3s;
      }

      .swiper-pagination-bullet {
        width: auto;
        height: auto;
        margin-right: 0 !important;
        background-color: transparent;
        font-size: 0;
        opacity: 1;

        &:first-child {
          margin-left: 0 !important;
        }

        &:not(:first-child) {
          position: relative;
          margin-left: 32px;
          &::before {
            position: absolute;
            top: 50%;
            left: -32px;
            z-index: -1;
            width: 32px;
            height: 1px;
            background: $white;
            opacity: 0.2;
            transform: translateY(-50%);
            content: "";
          }
        }

        &-active {
          i {
            width: 76px;
            height: 76px;
          }
        }
      }
    }
  }
}

.story_popup {
  .story {
    height: 100%;
  }

  .story_scrollbar {
    position: absolute;
    height: 256px;
    top: 50%;
    @include vw-convert-pc(right, 305);
    z-index: map-get($z-indexes, default);
    width: 4px;
    border-radius: 0;
    background: rgba(181, 177, 170, 0.5);
    opacity: 0;
    transform: translateY(-60px);
    transition: .5s;

    .swiper-scrollbar-drag {
      height: 25%!important;
      border-radius: 0;
      background: rgba(191, 166, 115, 0.5);
    }
  }
}

.house {
  &_popup {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    width: 0;
    height: 100%;
    transition: width 0.8s ease-out, visibility 0.8s ease-out;

    .btn_close {
      font-size: 0;
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: map-get($z-indexes, default);
      padding: 10px;
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }

    &.is_show {
      visibility: visible;
      right: initial;
      left: 0;
      width: 100%;

      .btn_close {
        opacity: 1;
      }
    }

    .story {
      &_popup {
        position: absolute;
        width: 100%;
        height: 100%;
        visibility: hidden;
        transition: visibility 1s ease-out;

        &#grendal {
          background: url(../img/pc/house/pc_house_007.jpg) center/cover no-repeat;
        }

        &#guntfell {
          background: url(../img/pc/house/pc_house_008.jpg) center/cover no-repeat;
        }

        &#erhard {
          background: url(../img/pc/house/pc_house_009.jpg) center/cover no-repeat;
        }

        &#collin {
          background: url(../img/pc/house/pc_house_010.jpg) center/cover no-repeat;
        }

        &#aindal {
          background: url(../img/pc/house/pc_house_011.jpg) center/cover no-repeat;
        }

        &.is_show {
          visibility: visible;

          .story_scrollbar {
            opacity: 1;
            transition: 1s ease 1s;
          }

          .story_head{
            animation: fadeIn 1s 0.6s ease-in both;
          }

          .story_box{
            &::before{
              animation: fadeIn 1s 0.6s ease-in both;
            }

            &:not(.story_box_aerius) {
              animation: none;

              .story_desc {
                &:first-child span {
                  animation: fadeInUp 1s .9s ease-out forwards;
                }

                &:nth-child(2) span {
                  animation: fadeInUp 1s 1.7s ease-out forwards;
                }

                &:nth-child(3) span {
                  animation: fadeInUp 1s 2.5s ease-out forwards;
                }

                &:nth-child(4) span {
                  animation: fadeInUp 1s 3.2s ease-out forwards;
                }
              }
            }

            &_aerius::after{
              opacity: 1;
              transition: 1s ease 1s;
            }
          }

          .swiper-slide-active {
            .story_desc {
              &:first-child span {
                animation: fadeInUp 1s .9s ease-out forwards;
              }

              &:nth-child(2) span {
                animation: fadeInUp 1s 1.7s ease-out forwards;
              }

              &:nth-child(3) span {
                animation: fadeInUp 1s 2.5s ease-out forwards;
              }

              &:nth-child(4) span {
                animation: fadeInUp 1s 3.2s ease-out forwards;
              }
            }
          }
        }
      }

      &_box {
        position: absolute;
        top: 50%;
        z-index: 1;
        max-width: 651px;
        text-align: center;
        transform: translateY(calc(-50% - 2px));
        transition: opacity 0.5s ease-out;

        &::before {
          position: absolute;
          top: 0;
          left: 50%;
          z-index: -1;
          width: 300px;
          height: 300px;
          background-repeat: no-repeat;
          background-size: 100%;
          opacity: 0;
          transition: 1.2s ease .2s;
          content: "";
        }

        &_aerius {
          &::after {
            position: absolute;
            bottom: -40px;
            left: 50%;
            @include sp-normal("ico_scroll");
            opacity: 0;
            transform: translateX(-50%);
            content: ""
          }
        }

        &_grendal {
          @include vw-convert-pc(right, 360);

          &::before {
            width: 266px;
            height: 376px;
            background-image: url(../img/common/ico_content_grendal.png);
            transform: translate(-50%, -42%);
          }
        }

        &_guntfell {
          @include vw-convert-pc(right, 376);

          &::before {
            background-image: url(../img/common/ico_content_guntfell.png);
            transform: translate(-50%, -40%);
          }
        }

        &_erhard {
          @include vw-convert-pc(right, 360);

          &::before {
            background-image: url(../img/common/ico_content_erhard.png);
            transform: translate(-50%, -37%);
          }
        }

        &_collin {
          @include vw-convert-pc(right, 318);

          &::before {
            background-image: url(../img/common/ico_content_collin.png);
            transform: translate(-50%, -33%);
          }
        }

        &_aindal {
          @include vw-convert-pc(right, 348);

          &::before {
            background-image: url(../img/common/ico_content_aindal.png);
            transform: translate(-50%, -40%);
          }
        }

        &_aerius {
          @include vw-convert-pc(right, 316);

          &::before {
            background-image: url(../img/common/ico_content_aerius.png);
            transform: translate(-50%, -36%);
          }
        }
      }

      &_head {
        opacity: 0;
      }

      &_title {
        font-size: 62px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        text-shadow: 0px 1px 2px #000;
        color: #bfa673;
      }

      &_sub_title {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.22;
        text-transform: uppercase;
        text-shadow: 0px 1px 2px #000;
        color: #938d83;

        @include respond-above($screen-md + 1) {
          white-space: nowrap;
        }
      }

      &_content {
        margin-top: 46px;
        min-height: 266px;
        overflow: hidden;
      }

      &_desc {
        overflow: hidden;
        font-family: beaufort-pro;
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 0.36px;
        text-shadow: 0px 1px 2px #000;
        color: #a19c92;

        span {
          display: inline-block;
          opacity: 0;
        }

        &:not(:first-child) {
          margin-top: 24px;
        }
      }

      &_slide_item {
        width: 100%;
        height: 100%!important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &:first-child {
          background-image: url(../img/pc/house/pc_house_012.jpg);
        }

        &:nth-child(2) {
          background-image: url(../img/pc/house/pc_house_013.png);
        }

        &:nth-child(3) {
          background-image: url(../img/pc/house/pc_house_014.jpg);
        }

        &:nth-child(4) {
          background-image: url(../img/pc/house/pc_house_015.jpg);
        }
      }
    }
  }
}

.armorial {
  overflow: hidden;
  height: 59.5px;

  &_wrap {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 219px;
    transform: translateX(-50%);
  }

  .swiper-wrapper {
    align-items: center;
  }

  &_item {
    width: 42px;
    font-size: 0;

    &.swiper-slide-active {
      width: 60px;

      .ico_armorial::before, &+.armorial_item .ico_armorial::before {
        display: inline-block;
      }
    }
  }

  &_next,
  &_prev {
    position: absolute;
    top: 50%;
    width: 114px;
    font-size: 0;
  }

  &_next {
    position: absolute;
    left: calc(50% + 40px);
    text-align: right;
  }

  &_prev {
    left: calc(50% - 154px);
    text-align: left;
  }

  .ico_armorial {
    position: relative;
    transition: 0.5s;

    &::before {
      display: none;
      position: absolute;
      top: 50%;
      width: 23px;
      height: 1px;
      background: $white;
      transform: translateY(-50%);
      opacity: .2;
      content: "";
    }

    &::before {
      right: 100%;
    }
  }
}

@media (max-width: $screen-md) {
  .section_house {
    .btn_back {
      top: 38px;
      left: 42px;
    }
  }

  .house {
    &_category {
      font-size: 21.5px;
      letter-spacing: 0.5em;
    }

    &_content {
      padding-left: 23.14%;
      transform: translateY(-56.8%);

      .house_pagination {
        bottom: -95px;
        left: 23.14%;
        height: 61px;

        i {
          width: 44px;
          height: 44px;
        }

        .swiper-pagination-bullet{
          &:not(:first-child) {
            margin-left: 28px;
          }

          &-active {
            i {
              width: 61px;
              height: 61px;
            }
          }
        }
      }
    }

    &_title {
      margin: 16px 0 14px -3px;
    }

    &_character {
      max-height: 1000px;
    }

    &_grendal {
      .house_character {
        left: 33.7%;
        height: 91.14vh;
      }
    }

    &_guntfell {
      .house_character {
        left: 33%;
        height: 91.53vh;
      }
    }

    &_erhard {
      .house_character {
        left: 38.1%;
        height: 90.88vh;
      }
    }

    &_collin {
      .house_character {
        left: 28.4%;
        height: 90.75vh;
      }
    }

    &_aindal {
      .house_character {
        left: 41.4%;
        height: 90.10vh;
      }
    }

    &_aerius {
      .house_character {
        left: 38.6%;
        height: 100vh;
      }
    }

    &_popup {
      .btn_close {
        top: 38px;
        right: 42px;
      }

      .story {
        &_popup {
          &#grendal {
            background-image: url(../img/ta/house/ta_house_007.jpg);
          }

          &#guntfell {
            background-image: url(../img/ta/house/ta_house_008.jpg);
          }

          &#erhard {
            background-image: url(../img/ta/house/ta_house_009.jpg);
          }

          &#collin {
            background-image: url(../img/ta/house/ta_house_010.jpg);
          }

          &#aindal {
            background-image: url(../img/ta/house/ta_house_011.jpg);
          }
        }

        &_title {
          font-size: 52px;
        }

        &_sub_title {
          margin-top: 10px;
          font-size: 16px;
          line-height: 1.38;
        }

        &_content {
          margin-top: 35px;
          min-height: 242px;
        }

        &_desc {
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: 0.32px;

          &:not(:first-child) {
            margin-top: 22px;
          }
        }

        &_box {
          transform: translateY(calc(-50% - 5px));

          &::before {
            width: 198px;
            height: 198px
          }

          &_grendal {
            right: 10.83vw;

            &::before {
              width: 160px;
              height: 226px;
              transform: translate(-50%, -39%);
            }
          }

          &_guntfell{
            right: 12.5vw;

            &::before {
              transform: translate(-50%, -37%);
            }
          }

          &_erhard{
            right: 12.10vw;
          }

          &_collin{
            right: 12.01vw;
          }

          &_aindal{
            right: 12.69vw;

            &::before {
              transform: translate(-50%, -37%);
            }
          }

          &_aerius{
            width: 462px;
            right: 13.37vw;
            transform: translateY(calc(-50% + 5px));

            &::before {
              transform: translate(-50%, -37%);
            }

            &::after {
              bottom: -32px;
            }
          }
        }

        &_slide_item {
          &:first-child {
            background-image: url(../img/ta/house/ta_house_012.jpg);
          }

          &:nth-child(2) {
            background-image: url(../img/ta/house/ta_house_013.jpg);
          }

          &:nth-child(3) {
            background-image: url(../img/ta/house/ta_house_014.jpg);
          }

          &:nth-child(4) {
            background-image: url(../img/ta/house/ta_house_015.jpg);
          }
        }

        &_scrollbar {
          @include vw-convert-tb(right, 84);
          height: 238px;
          transform: translateY(-42px);
        }
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .section_house {
    .btn_back {
      top: 15px;
      left: 15px;
    }
  }

  .house {
    &_category {
      font-size: 3.33vw;
    }

    &_content {
      top: initial;
      bottom: 4.86vw;
      padding-bottom: 26.25vw;
      padding-left: 27px;
      transform: translateY(0);

      .house_pagination {
        display: none;
      }
    }

    &_title {
      margin: 8px 0 13px -2px;
      img {
        height: 8.47vw;
      }
    }

    &_grendal {
      .house_character {
        bottom: 9.92%;
        left: 7.36%;
        height: 76.48%;
      }
    }

    &_guntfell {
      .house_character {
        bottom: 0;
        left: 6.25%;
        height: 86.95%;
      }
    }

    &_erhard {
      .house_character {
        bottom: 0;
        left: 13.33%;
        height: 86.71%;
      }
    }

    &_collin {
      .house_character {
        bottom: 2.03%;
        left: 0;
        height: 85.54%;
      }
    }

    &_aindal {
      .house_character {
        bottom: 10.93%;
        left: 15.69%;
        height: 75.78%;
      }
    }

    &_aerius {
      .house_character {
        bottom: 3.04%;
        left: 2.50%;
        height: 95.07%;
      }
    }

    &_popup {
      transition: width 0.5s ease-out, visibility 0.5s ease-out;

      .btn_close {
        top: 15px;
        right: 14px;
      }

      .story {
        &_popup {
          &#grendal {
            background-image: url(../img/mo/house/mo_house_007.jpg);
          }

          &#guntfell {
            background-image: url(../img/mo/house/mo_house_008.jpg);
          }

          &#erhard {
            background-image: url(../img/mo/house/mo_house_009.jpg);
          }

          &#collin {
            background-image: url(../img/mo/house/mo_house_010.jpg);
          }

          &#aindal {
            background-image: url(../img/mo/house/mo_house_011.jpg);
          }
        }

        &_box {
          top: initial;
          right: initial;
          bottom: 13.61vw;
          left: initial;
          padding-left: 7.63vw;
          min-height: 77.22vw;
          text-align: left;
          transform: translate(0);
          box-sizing: border-box;

          &::before {
            left: 12.67vw;
            width: 120px;
            height: 120px;
            transform: translate(-50%, -32%);
          }

          &_grendal {
            &::before {
              left: 0;
              width: 120px;
              height: 170px;
              transform: translateY(-38%);
            }
          }

          &_aerius {
            width: 100%;

            &:after {
              bottom: 0;
              width: 14.5px;
              height: 29.5px;
              background-image: url(../img/common/ico_scroll_mo.png);
              @include background-full();
            }
          }
        }

        &_content{
          min-height: auto;
          max-height: initial;
          margin-top: 3.33vw;
        }

        &_title {
          font-size: 10.55vw;
        }

        &_sub_title {
          margin-top: 1.11vw;
          font-size: 3.33vw;
          line-height: 1.25;
          line-height: 1.16;
        }

        &_desc {
          font-size: 3.33vw;
          line-height: 1.25;
          letter-spacing: 0.04em;

          &:not(:first-child) {
            margin-top: 4.166vw;
          }
        }

        &_slide_item {
          &:first-child {
            background-image: url(../img/mo/house/mo_house_012.jpg);
          }

          &:nth-child(2) {
            background-image: url(../img/mo/house/mo_house_013.jpg);
          }

          &:nth-child(3) {
            background-image: url(../img/mo/house/mo_house_014.jpg);
          }

          &:nth-child(4) {
            background-image: url(../img/mo/house/mo_house_015.jpg);
          }
        }

        &_scrollbar {
          top: initial;
          right: 7.77vw;
          bottom: 13.88vw;
          width: 2px;
          height: 53.33vw;
          transform: translate(0);
        }
      }
    }
  }

  .armorial {
    &_wrap {
      display: block;
    }
  }
}

@media (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .section_house {
    .btn_back {
      top: 17px;
      left: 16px;
    }
  }

  .house {
    &_content {
      top: 50%;
      bottom: initial;
      padding-left: 25%;
      padding-bottom: 0;
      transform: translateY(-56.8%);

      .house_pagination {
        left: 25%;

        .swiper-pagination-bullet:not(:first-child)::before {
          left: -28px;
          width: 28px;
        }
      }
    }

    &_info {
      height: 105px;
    }

    &_category {
      font-size: 15px;
    }

    &_title {
      height: 30.5px;
      margin: 10px 0 10px -2px;

      img {
        height: 100%;
      }
    }

    &_pagination {
      i {
        background-size: 100%;
        background-position: 0;
      }
    }

    &_grendal, &_guntfell, &_erhard, &_collin, &_aindal, &_aerius{
      .house_character {
        left: initial;
        right: 10%;
        bottom: 0;
      }
    }

    &_grendal {
      .house_character {
        height: 91.14vh;
      }
    }

    &_guntfell {
      .house_character {
        height: 91.53vh;
      }
    }

    &_erhard {
      .house_character {
        height: 90.88vh;
      }
    }

    &_collin {
      .house_character {
        height: 90.75vh;
      }
    }

    &_aindal {
      .house_character {
        height: 90.10vh;
      }
    }

    &_aerius {
      .house_character {
        height: 100vh;
      }
    }

    &_popup {
      .btn_close {
        top: 17px;
        right: 16px;
      }

      .story {
        &_box {
          top: 50%;
          right: 10.83vw;
          bottom: initial;
          left: initial;
          min-height: 233px;
          text-align: center;
          transform: translateY(calc(-50% - 5px));
        }

        &_title {
          font-size: 28px;
        }

        &_sub_title {
          margin-top: 5px;
          font-size: 14px;
        }

        &_content {
          min-height: 165px;
          margin-top: 15px;
        }

        &_desc {
          font-size: 13px;
          line-height: 1.25;

          &+.story_desc {
            margin-top: 8px;
          }
        }

        &_scrollbar {
          top: 50%;
          bottom: initial;
          height: 150px;
          transform: translateY(-42px);
        }

        &_slide_item {
          &:first-child {
            background-image: url(../img/pc/house/pc_house_012.jpg);
          }

          &:nth-child(2) {
            background-image: url(../img/pc/house/pc_house_013.png);
          }

          &:nth-child(3) {
            background-image: url(../img/pc/house/pc_house_014.jpg);
          }

          &:nth-child(4) {
            background-image: url(../img/pc/house/pc_house_015.jpg);
          }
        }

        &_box {
          &::before {
            width: 120px;
            height: 120px;
          }

          &_grendal {
            &::before{
              width: 96px;
              height: 135.6px;
            }
          }
        }
      }
    }
  }

  .armorial_wrap {
    bottom: -95px;
  }
}
