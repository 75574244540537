body,
input,
select,
textarea,
button {
  font-family: CormorantSC, sans-serif;
}

body {
  background-color: $black;
  overflow-x: hidden;
}

.blind,
caption span,
legend {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  text-indent: -9999px;
}

.skip {
  height: 0;
}

.skip a {
  display: block;
  position: absolute;
  left: 0;
  top: -100px;
  width: 100%;
  height: 25px;
  text-align: center;
}

.skip a:focus,
#skip a:active {
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 10px 0;
  background: #000;
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

#fullpage {
  &.is_full {
    transform: translate3d(0px, 0px, 0px)!important;
  }
}


.section {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  opacity: 0;
  transition: all 1s ease-in-out;

  &.active {
    opacity: 1;
    z-index: 1;
  }

  &_media {
    overflow: hidden;

    &.active +.section_footer,&+.section_footer.active {
      position: relative;
    }
  }

  &.is_visible {
    z-index: 1;
    opacity: 1;
  }

  .background {
    position: absolute;
    @include absolute-full;

    .img,
    .video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .box_content {
    position: absolute;
  }
}

i {
  display: inline-block;
}

.side_deco {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: map-get($z-indexes, default);
  padding-top: 50px;
  font-size: 12px;
  color: rgba(205, 204, 191, 0.4);
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: translateY(-50%);

  &::before {
    position: absolute;
    left: 50%;
    top: 0;
    height: 24px;
    width: 1px;
    transform: translateX(-50%);
    background-color: rgba(205, 204, 191, 0.4);
    content: "";
  }
}

@media (orientation:landscape){
  .only_portrait{
    display: none;
  }
}

@media (orientation:portrait){
  .only_landscape{
    display: none;
  }
}

@media (max-width: $screen-sm) {
  .above_tablet {
    display: none;
  }
}

@media (min-width: ($screen-md + 1)) {
  .below_tablet {
    display: none;
  }
}

@media (max-width: $screen-md) {
  .only_pc {
    display: none;
  }
}

.only_tablet {
  display: none;
  @include only-tablet{
    display: block;
  }
}

.without_tablet {
  @include only-tablet{
    display: none;
  }
}

@media (min-width: ($screen-sm + 1)) {
  .only_mo {
    display: none;
  }
}

@media (max-width:1023px) and (max-height:767px) and (orientation:landscape) {
  .no_landscape_mo {
    display: none;
  }

  .landscape_mo {
    display: block;
  }
}

